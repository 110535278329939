import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import Title from '../../components/title'
import Header from '../../components/header'
import Container from '../../components/container'
import FeatureBlock from '../../components/feature-block'
import Layout from '../../layout'
import Image from '../../components/image'
import Brands from '../../components/brands'
import AnimatedArticle from '../../components/animated-article'
import AnimatedAside from '../../components/animated-aside'
import P from '../../components/paragraph'
import BrandBadge from '../../components/brand-badge'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const NodPage = () => {
  const images = useStaticQuery(graphql`
    query {
      bottle: file(relativePath: { eq: "brands/nod/bottle-nod.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      group: file(relativePath: { eq: "bespoke/nod2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='NOD'
        description={`Inspired by the spirit of adventure NOD was born. The ability to say 'yes' and the desire to live life to its fullest is at the core of every NOD fragrance. An enigmatic collection of parfums, designed to experience and to be experienced.`}
      />
      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad badge>
            <Title fancy>NOD</Title>
            <P>
              Nod is more than just a fragrance brand, it is an attitude for living.
              Encapsulating the spirt of adventure and a willingness to participate in life.   
              Using contemporary olfactory language and aesthetic, Nod stimulates and inspires the wearer, emboldening them to be in the now. 
              These parfums are designed to experience and to be experienced.
            </P>
            <BrandBadge brand='nod' />
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              css={`
                @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 100px;
                }
              `}
              fluid={images.bottle.childImageSharp.fluid}
              notch='bottomLeftSmall'
              alt='NOD bottle'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container width={1100} noPadding css='margin-top: -50px'>
        <FeatureBlock grey={false} center>
          <AnimatedAside right>
            <CustomReactPlayer url='https://vimeo.com/761443002/bcfc2cd7a9' playing={true} muted={true} loop={true} width={250}/>
          </AnimatedAside>
          <AnimatedArticle>
            <P>
              NOD: a little gesture that means so much. In nodding we encourage others to respond positively, making it infectious and creating a ripple effect of zeal.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container> 

      <Container width={1100} noPadding overflow='hidden' css='margin-top: -50px; margin-bottom: 50px;'>
        <FeatureBlock grey={false} center fullWidth>
          <AnimatedArticle>
            <P>
              To NOD is to accept, to respond positively to a conversation, a proposition an opportunity. 
              Nod gives one courage and confidence to progress; moving forward is what NOD is all about.  
              Triggering positive intension and a sense of liberation; Nod is instant, Nod is assured, Nod is respect, Nod is an energy.
            </P>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={images.group.childImageSharp.fluid}
              shadow={false}
              alt='Perfume bottle'
              notch='topRightSmall'
              css={`
                max-width: 600px;
                @media (max-width: ${({ theme }) => theme.breakpoint.large}) {
                  max-width: 300px;
                  margin: 0 auto;
                }
              `}
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container width={1200}>
        <Title fancy>Other Brands</Title>
        <Brands />
      </Container>
    </Layout>
  )
}

const CustomReactPlayer = styled(ReactPlayer)`
  transform: scale(1.25);
`



export default NodPage
