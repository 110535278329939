import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { lighten } from 'polished'

const BadgeContainer = styled.div`
  background: ${({ theme }) => lighten(0.1, theme.color.dark)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 260px;
  margin-bottom: -250px;
  margin-top: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    height: 150px;
    width: 150px;
  }
`

const Logo = styled(Img)`
  width: 240px;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    width: 100px;
  }

  img {
    object-fit: scale-down !important;
  }
`

const BrandBadge = ({ brand }) => {
  const data = useStaticQuery(graphql`
    query {
      boadicea: file(relativePath: { eq: "brands/altlogos/boadicea.png" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cpp: file(relativePath: { eq: "brands/altlogos/cpp.png" }) {
        childImageSharp {
          fluid(maxWidth: 190, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      horatio: file(relativePath: { eq: "brands/altlogos/horatio.png" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kolo: file(relativePath: { eq: "brands/altlogos/kolo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nod: file(relativePath: { eq: "brands/altlogos/nod.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      quintesscent: file(
        relativePath: { eq: "brands/altlogos/quintesscent.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rajani: file(relativePath: { eq: "brands/altlogos/rajani.png" }) {
        childImageSharp {
          fluid(maxWidth: 190, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shl: file(relativePath: { eq: "brands/altlogos/shl.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BadgeContainer>
      <Logo
        alt={brand}
        fluid={data[brand].childImageSharp.fluid}
        width={data[brand].childImageSharp.fluid.presentationWidth}
      />
    </BadgeContainer>
  )
}

BrandBadge.propTypes = {
  brand: PropTypes.string,
}

export default BrandBadge
